
import myNav from "@/components/myNav.vue"; 
import{toRefs,defineComponent, }from "vue"
import { reactive } from '@vue/reactivity'
    export default defineComponent({
      name:"app",
      components:{
        myNav
      },
      setup(){
        const state=reactive({
           
        });

        return{
            ...toRefs(state),
        }
      }
    })
