
import{toRefs, defineComponent, onMounted,}from "vue";
import { useRouter ,useRoute} from "vue-router";
import { reactive } from '@vue/reactivity'
export default defineComponent({
  name:"myNav",
  setup() {
    const router=useRouter();
    const route=useRoute();
    const state=reactive({
            menuList:[
              {id:'1',name:'首页',left:'36px',path:'/'}, 
              {id:'2',name:'关于我们',left:'120px',path:'/aboutUs'}, 
              {id:'3',name:'项目案例',left:'210px',path:'/products'}, 
              {id:'4',name:'联系我们',left:'310px',path:'/contactUs'}
            ],
            leftPostion:'' as any,
            isMenu:false,
        });
        const  changpage=(item:any)=>{       
          router.push({path:item.path})
          state.isMenu=false
        };
        const isShowMenu=()=>{
          state.isMenu=!state.isMenu
        };
        const sharePinterest=()=>{
            window.open(
              'https://pinterest.com/pin/create/button' +
                objectToGetParams({
                  url: "http://www.mx-design.com"
                }),
              '__blank'
            );
        }
        const objectToGetParams=(object)=>{
            return (
              '?' +
              Object.keys(object)
                .filter(key => !!object[key])
                .map(key => `${key}=${encodeURIComponent(object[key])}`)
                .join('&')
            );
          }



        onMounted(()=>{
            // console.log(route)
            // console.log(route.meta)
            router.beforeEach((to,from,next)=>{
              // 　　console.log(to.meta)
                  state.leftPostion=to.meta.left
                  next();
          　　})
        })


        return{
            ...toRefs(state),changpage,isShowMenu,sharePinterest,objectToGetParams
        }
  }

})
