
import{toRefs, defineComponent}from "vue";
import { useRouter } from "vue-router";
import { reactive } from '@vue/reactivity';
export default defineComponent({
  name:"productsList",
  setup() {
    const router=useRouter();
    const state=reactive({
      listData:[
          {id:'1',src:require('../assets/images/productImg/jinrong/t01.jpg'),name:'金融类 办公室',},
          {id:'2',src:require('../assets/images/productImg/xingzheng/t01.jpg'),name:'行政 会议中心',},
          {id:'3',src:require('../assets/images/productImg/huagong/t01.jpg'),name:'化工类 办公室',},
          {id:'4',src:require('../assets/images/productImg/lvsuo/t01.jpg'),name:'律所类 办公室',},
          {id:'5',src:require('../assets/images/productImg/qiche/t01.jpg'),name:'汽车类 办公室',},
          {id:'6',src:require('../assets/images/productImg/tijian/t01.jpg'),name:'体检中心',},
          {id:'7',src:require('../assets/images/productImg/yanfa/t01.jpg'),name:'研发中心',},
          {id:'8',src:require('../assets/images/productImg/yiliaokongjian/t01.jpg'),name:'医疗空间',},
          {id:'9',src:require('../assets/images/productImg/yiliaolei/t01.jpg'),name:'医药类 办公室',},
        ],
    });
    const  changpage=(item:any)=>{
          router.push({path:"/productsDeatils",
            query:{
              id:item.id
            }
          })
        };



    return{
        ...toRefs(state),changpage
    }
  }
})
