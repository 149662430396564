
import productsList from "@/components/productsList.vue"; 
import{toRefs, defineComponent}from "vue";
import { useRouter } from "vue-router";
import { reactive } from '@vue/reactivity'
export default defineComponent({
  name:"mainIndex",
  components:{productsList},
  setup() {
    const router=useRouter();
    const state=reactive({
        bannerLise:[
          {id:'8',src:require('../assets/images/banner01.jpg'),name:'医疗空间'},
          {id:'3',src:require('../assets/images/banner02.jpg'),name:'化工类 办公室'},
          {id:'7',src:require('../assets/images/banner03.jpg'),name:'研发中心'}
        ],
    });
    const  changpage=(item:any)=>{
      router.push({path:"/productsDeatils",
            query:{
              id:item.id
            }
          })
    };



    return{
        ...toRefs(state),changpage
    }
  }
})
