import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import "./assets/css/normalize.scss";
import * as Icons from '@ant-design/icons-vue';

const app=createApp(App);
app.use(Antd).use(store).use(router).mount("#app");
// 循环使用全部全部图标
const icons: any = Icons;
for (const i in icons) {
    // 全局注册一下组件
    app.component(i, icons[i])
}