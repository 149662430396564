/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2022-11-08 10:12:32
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-02-06 10:09:08
 * @FilePath: \fmauto-webd:\ZHAOYUYA\maixi-design\maixiWeb\src\router\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import main from "../views/main.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "main",
    meta:{
      left:'36px'
    },
    component: main,
  },  
  {
    path: "/aboutUs",
    name: "aboutUs",
    meta:{
      left:'120px'
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutUs.vue"),
  },
  {
    path: "/products",
    name: "products",
    meta:{
      left:'210px'
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/products.vue"),
  },
  {
    path: "/productsDeatils",
    name: "productsDeatils",
    meta:{
      left:'210px'
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/productsDeatils.vue"),
  },
  {
    path: "/contactUs",
    name: "contactUs",
    meta:{
      left:'310px'
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/contactUs.vue"),
  },
  
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history:createWebHashHistory(),
  routes,
});

export default router;
